<template>
  <b-container class="page-container">
    <bread-crumbs :currentpage="$t('customer-information')" />
    <h1 class="text-center">{{ $t('customer-information') }}</h1>
    <p>
      {{ $t('required-information-for-order') }}
    </p>
    <b-row>
      <b-col sm="12" class="mb-3"> </b-col>
      <b-col sm="12" md="6" xl="6">
        <h4>{{ $t('continue-by-logging-in') }}</h4>
        <p>{{ $t('log-in-with-below-form') }}</p>
        <hr />
        <b-card>
          <login />
        </b-card>
      </b-col>
      <b-col sm="12" md="6" class="pt-4 pt-lg-0">
        <h4>{{ $t('no-login-credentials') }}?</h4>
        <p>{{ $t('make-an-account-or-continue-without-registration') }}</p>
        <hr />
        <p v-if="!allowAnonymous">
          {{ this.$t('cannot-anonymous-checkout-longer-14') }}
        </p>
        <router-link
          :to="{ name: 'register', params: { lang: this.$i18n.locale } }"
          class="btn btn-primary mt-3"
          register
          >{{ $t('register') }}</router-link
        >
        <router-link
          v-if="allowAnonymous"
          :to="{
            name: 'anonymous-checkout',
            params: { lang: this.$i18n.locale }
          }"
          class="btn btn-primary mt-3 ml-3"
          register
          >{{ $t('continue-without-registration') }}</router-link
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Login from '@/components/login/Login'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(isSameOrAfter)
dayjs.extend(customParseFormat)

export default {
  components: {
    Login
  },
  computed: {
    startDate: function() {
      return this.$store.state.DateTimeExpectedStart
    },
    endDate: function() {
      return this.$store.state.DateTimeExpectedEnd
    },
    allowAnonymous: function() {
      return dayjs(this.startDate, 'DD/MM/YYYY')
        .add(14, 'days')
        .isSameOrAfter(dayjs(this.endDate, 'DD/MM/YYYY'))
    }
  }
}
</script>

<style></style>
